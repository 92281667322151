<template>
  <div class="app-container">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item>阅卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="app-content">
      <div class="left" style="background-color: white; height: 100%">
        <div class="left-top">待批阅试卷</div>
        <div class="examList">
          <div
            :style="IsFocus === l.examId ? 'background: #2d8cf02e;font-weight: bold;' : ''"
            v-show="list.length !== 0"
            class="list"
            @click="
              getReviewQ(l.examId)
              IsFocus = l.examId
            "
            v-for="(l, lIdnex) in list"
            :key="lIdnex"
          >
            {{ l.name }}
          </div>
          <div class="none" v-show="list.length === 0">暂无批阅试卷</div>
        </div>
      </div>
      <div id="examQuestionList" class="app-tpRight" style="background-color: white">
        <div v-show="questionList.length !== 0" class="data">
          <div class="app-topic" v-for="(q, qIndex) in questionList" :key="qIndex">
            <div style="width: 100%;justify-content: space-between;margin-top: 5px;margin-left: 5px;">
              <span style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{ qIndex + 1 }}、{{ removeHtml(q.stem) }}</span>
              <div>
                <el-button style="padding-top: 2px; padding-right: 10px" size="mini" type="text" @click="review(IsFocus, q)">批阅</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="none" v-show="questionList.length === 0">
          暂无待批阅题目
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reviewApi from '../../../api/review'
export default {
  name: 'index',
  data() {
    return {
      list: [],
      questionList: [],
      IsFocus: 0,
      IsLoading: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    areaLoad(elementName) {
      this.IsLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        target: document.querySelector(`#${elementName}`)
      })
    },
    getList() {
      reviewApi.reviewExamList().then(res => {
        this.list = res.res
        if (this.list.length !== 0) {
          if (this.$route.params.examId) {
            this.IsFocus = Number(this.$route.params.examId)
          } else {
            this.IsFocus = Number(this.list[0].examId)
          }
          this.getReviewQ(this.IsFocus)
        }
      })
    },
    getReviewQ(id) {
      this.areaLoad('examQuestionList')
      reviewApi.reviewExamQuestion(id).then(res => {
        this.questionList = res.res
        this.IsLoading.close()
      })
    },
    review(id, data) {
      this.$router.push({
        name: 'checkDetail',
        params: { examId: id, questionId: data.questionId }
      })
    },
    removeHtml(html) {
      return html
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(&rdquo;)/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&mdash;/g, '-')
        .replace(/&nbsp;/g, '')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/<[\w\s"':=\/]*/, '')
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  min-height: 50px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-width: 0 0 0.5px 0;
  border-style: solid;
  border-color: #f3f3f3;
  padding-left: 10px;
  word-break: break-all;
  text-align: left;
  &:first-child {
    margin-top: 6px;
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &:not(:first-child) {
    margin-top: 6px;
  }
  &:hover {
    cursor: pointer;
    background-color: #2d8cf02e;
  }
}

.data {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px 5px 0 5px;
  div {
    display: flex;
    justify-content: flex-start;
  }
  flex-direction: column;

  .app-topic {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &:not(:first-child) {
      margin-top: 5px;
    }
    /*&:nth-child(2n + 1) {*/
    /*  background-color: white;*/
    /*}*/
    /*&:nth-child(2n) {*/
    /*  background-color: #f8f8f9;*/
    /*}*/
  }
}
</style>
